import React, {useState} from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import _ from 'lodash';
import { Link } from 'gatsby';
import { FontAwesomeIcon  } from '@fortawesome/react-fontawesome';
import Header from './Header';
import Comments from './Comments';
import CommentIcon from './CommentIcon';
import LatestComments from './LatestComments';
import LatestPosts from './LatestPosts';
import Footer from './Footer';
import TtsButton from './TtsButton';

const PostTemplateDetails = props => {
  const {  wpPost, allWpCategory, allWpComment, pageContext } = props.data;

  const { 
    title, 
    date, 
    content, 
    featuredImage, 
    databaseId,
    commentCount,
    commentStatus,
  } = wpPost;  

  const categoryNames = allWpCategory.nodes
    .map(node => ({ name: node.name, uri: node.uri }))
    .filter(({ name }) => name !== 'Uncategorized');

  const [count, setCount] = useState(commentCount ?? 0);
  const handleCountUpdate = num => setCount(num);
  const tags = wpPost.tags.nodes.map(edge => edge.name);
  const commentActive = commentStatus === 'open';
  const back = e => {
    e.preventDefault();
    history.back();
  }
 
  return (
    <>
      <Header location="/" />            
      <main className="js-blog-posts">        
        <article className="container container--wide post">          
          <header>                       
            <p>
              <span className="comments">                
                { commentActive && <CommentIcon count={count} /> }
                <TtsButton text={`${date}. ${title}. ${commentCount ?? 0} ${commentCount === 1 ? 'Kommentar' : 'Kommentare'}. ${content}`} />
              </span> 
              <time dateTime={date}>
                {date}
              </time>  
            </p>            
            <h1>{title}</h1>            
          </header>
					{   
            featuredImage && <GatsbyImage image={featuredImage.node.localFile.childImageSharp.gatsbyImageData} className="feature_image" style={{clear: 'both'}} alt={title} />							
					}
          <section
            className="longform drop"
            dangerouslySetInnerHTML={{ __html: `${content}` }}
          />
          {tags.length > 0 && (
            <section className="longform drop container container--wide" style={{textAlign: 'center', position: 'relative', clear: 'both'}}>              
              <FontAwesomeIcon aria-hidden title="Tags" icon="tags"style={{fontSize: '1.75em', position: 'absolute', left: 0, top: -15, color: 'var(--primary-text)'}} />
              <hr />
              {tags.map(tag => (
                <Link
                  className="tag"
                  style={{ marginRight: '.25rem' }}
                  to={`/tag/${_.kebabCase(tag)}`}
                >
                  {tag}
                </Link>
              ))}
              <hr style={{margin: '0 auto 1em'}} />
            </section>
          )}
          <div className="container container--wide">
            <a href="#back" className="button" onClick={back} aria-label="weiter stöbern">
              Weiter stöbern
            </a>
          </div>
        </article>        
        { commentActive && <Comments data={allWpComment.edges} id={databaseId} onCountUpdate={handleCountUpdate} /> }
        <div className="container container--wide" style={{display: 'flex', gap: '1em', flexWrap: 'wrap'}}>
          <LatestPosts />
          <LatestComments />      
        </div>           
      </main>      
      <Footer categories={categoryNames} />
    </>
  );
};

export default PostTemplateDetails;
